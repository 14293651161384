import io from 'socket.io-client';
import feathers from '@feathersjs/client';
const auth = require('@feathersjs/authentication-client');

let app;
if (typeof window.require !== 'undefined') {
   console.log('Electron');
   var remote = window.require('electron').remote;
   global.electron = remote.getGlobal('electron');
   app = global.electron.feathers;
} else {
   console.log('Web');
   const host = process.env.REACT_APP_URL_FEATHERS;
   const socket = io(host, {
      // transports: ['websocket', 'flashsocket', 'xhr-polling', 'htmlfile']
      transports: ['websocket'],
      timeout: 60000,
      autoConnect: true
   });

   socket.on('reconnect_attempt', () => {
      socket.io.opts.transports = ['websocket'];
   });

   // Set up Feathers client side
   app = feathers();

   // Register socket.io
   app.configure(
      feathers.socketio(socket, {
         timeout: 60000
      })
   );
   // app.configure(feathers.authentication());

   app.configure(auth({ storage: window.localStorage }));

   // app.io.on('connect', function() {
   //   if (app.get('user')) {
   //     app.io.emit('register-station', {
   //       ...app.get('user'),
   //       isStation: true
   //     });
   //   }
   // });

   app.set('user', {});
}

export default app;
